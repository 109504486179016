import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Col, Row, Table, Pagination, FormControl, InputGroup, Form, Modal, Spinner } from "react-bootstrap";
import { FaSearch, FaChevronUp, FaChevronDown } from "react-icons/fa"; // Import the search icon
import { student_api, student_into_apis, package_student_api } from "../../helper/Api";
import { usePackage } from '../../context/PackageContext'; // Import the usePackage hook

const formatDateWithoutTimezone = (dateString) => {
    // Create a Date object from the string
    const date = new Date(dateString);

    // Extract hours and minutes directly
    let hours = date.getUTCHours();  // UTC hours without timezone adjustment
    let minutes = date.getUTCMinutes();

    // Convert to 12-hour format
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    // Pad minutes with leading zero if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${hours}:${formattedMinutes} ${period}`;
    // Get the updated date and time
    const adjustedDatePart = date.toISOString().split('T')[0];  // YYYY-MM-DD part

    // Return the date and time in the desired format (dd/mm/yyyy hh:mm:ss)
    return `${adjustedDatePart.split('-').reverse().join('/')} ${formattedTime}`;
};
  

function StudentInfoModal({ show, handleClose, data }) {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Student Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                        <tr>
                            <td><strong>Full Name:</strong></td>
                            <td>{data.userData?.fullname}</td>
                        </tr>
                        <tr>
                            <td><strong>Contact:</strong></td>
                            <td>{data.userData?.contact}</td>
                        </tr>
                        <tr>
                            <td><strong>Email:</strong></td>
                            <td>{data.userData?.email}</td>
                        </tr>
                    </tbody>
                </Table>

                {/* Packages */}
                <h5>Packages</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Batch Start</th>
                            <th>Valid Till</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.userPackages && data.userPackages.length > 0 ? (
                            data.userPackages.map((packages, index) => (
                                <tr key={index}>
                                    <td>{packages.title}</td>
                                    <td>{formatDateWithoutTimezone(packages.batch_start)}</td>
                                    <td>{formatDateWithoutTimezone(packages.valid_till)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Transaction Details */}
                <h5>Transaction</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Mode</th>
                            <th>Amount</th>
                            <th>Transaction Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.userTransactData && data.userTransactData.length > 0 ? (
                            data.userTransactData.map((transact, index) => (
                                <tr key={index}>
                                    <td>{transact.mode}</td>
                                    <td>{transact.amount}</td>
                                    <td>{formatDateWithoutTimezone(transact.transaction_date)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Enquiry Details */}
                <h5>User Enquiry & Ticket</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Message</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Assigned To</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.userEnquiryData && data.userEnquiryData.length > 0 ? (
                            data.userEnquiryData.map((enquiry, index) => (
                                <tr key={index}>
                                    <td>{enquiry.message}</td>
                                    <td>{formatDateWithoutTimezone(enquiry.date)}</td>
                                    <td>{enquiry.statusDetail}</td>
                                    <td>{enquiry.assignedDetail.join(', ')}</td>
                                    <td>{enquiry.enquiry_type === 1 ? 'Ticket' : 'Enquiry'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Workshop Details */}
                <h5>Workshop Details</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Mode</th>
                            <th>Title</th>
                            <th>College</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.userWorkshopData && data.userWorkshopData.length > 0 ? (
                            data.userWorkshopData.map((workshop, index) => (
                                <tr key={index}>
                                    <td>{workshop.location}</td>
                                    <td>{workshop.workshopMode}</td>
                                    <td>{workshop.workshopTitle[0]}</td>
                                    <td>{workshop.college_name || 'N/A'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Mains Result Details */}
                <h5>Mains Result</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Test Title</th>
                            <th>Score</th>
                            <th>Status Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.userResultData && data.userResultData.length > 0 ? (
                            data.userResultData.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.testTitle[0]}</td>
                                    <td>{result.score}</td>
                                    <td>{result.statusDetail}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Free Mains Details */}
                <h5>Free Mains</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Received On</th>
                            <th>Status</th>
                            <th>Test Detail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.freeMainsDetailData && data.freeMainsDetailData.length > 0 ? (
                            data.freeMainsDetailData.map((item, index) => (
                                <tr key={index}>
                                    <td>{formatDateWithoutTimezone(item.received_on)}</td>
                                    <td>{item.statusDetail}</td>
                                    <td>{item.testDetail.map(detail => `${detail.typeDetail}: ${detail.title}`).join(', ')}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Free Prelims Details */}
                <h5>Free Prelims Details</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Test Category Detail</th>
                            <th>Max Score</th>
                            <th>Score</th>
                            <th>Mode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.freePrelimsDetailData && data.freePrelimsDetailData.length > 0 ? (
                            data.freePrelimsDetailData.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.testDetail[0].title}</td>
                                    <td>{detail.testDetail[0].testCategoryDetail}</td>
                                    <td>{detail.testDetail[0].max_marks}</td>
                                    <td>{detail.total_score}</td>
                                    <td>{detail.modeDetail}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Free Downloads Details */}
                <h5>Downloads</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.downloadsLogDetailData && data.downloadsLogDetailData.length > 0 ? (
                            data.downloadsLogDetailData.map((item, index) => (
                                <tr key={index}>
                                    <td>{formatDateWithoutTimezone(item.created_date)}</td>
                                    <td>{item.title.join(', ')}</td>
                                    <td>{item.typeDetail}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
}

const StudentInfo = () => {
    const { packageId } = usePackage(); // Get packageId from context
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchQuery, setSearchQuery] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(""); 
    const [isTableExpanded, setIsTableExpanded] = useState(false); // State for toggling table visibility

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'fullname', headerName: 'Name', width: 200 },
        { field: 'email', headerName: 'Email ID', width: 280 },
        { field: 'contact', headerName: 'Contact No.', width: 120 },
        { field: 'address', headerName: 'Address', width: 450 },
        { field: 'infoBTN', headerName: '', width: 80 },
    ];


    const fetchDataForItem = useCallback(async (item_id, userData) => {
        try {
            const userTransactData = (await student_into_apis.user_transact(item_id)).data;
            const userEnquiryData = (await student_into_apis.user_enquiry(item_id)).data;
            const userWorkshopData = (await student_into_apis.user_workshop(item_id)).data;
            const userResultData = (await student_into_apis.user_result(item_id)).data;
            const freeMainsDetailData = (await student_into_apis.free_mains_detail(item_id)).data;
            const freePrelimsDetailData = (await student_into_apis.free_prelims_detail(item_id)).data;
            const downloadsLogDetailData = (await student_into_apis.downloads_log_detail(item_id)).data;
            const userPackages = (await student_into_apis.user_packages(item_id)).data;
            
            setModalData({
                userTransactData,
                userEnquiryData,
                userWorkshopData,
                userResultData,
                freeMainsDetailData,
                freePrelimsDetailData,
                downloadsLogDetailData,
                userData,
                userPackages,
            });

            setShowModal(true);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    
    const getCalenderEvents = useCallback(async () => {
        setLoading(true); // Optional: Set loading state

        try {
            const apiToUse = packageId ? () => package_student_api(packageId) : () => student_api();
            const { data = [] } = await apiToUse();
            
            const start = startDate ? new Date(startDate) : new Date(-8640000000000000); // Min Date
            const end = endDate ? new Date(endDate) : new Date(); // Max Date

            // Filter data based on date range if packageId is present
            const filteredData = packageId ? data.filter(item => {
                const transactionDate = new Date(item.transactionDate);
                return (transactionDate >= start && transactionDate < new Date(end).setDate(end.getDate() + 1));
            }) : data;
            
            const transformedData = filteredData.map((item, index) => {
                const userData = {
                    fullname: item.fullname,
                    email: item.email,
                    contact: item.contact
                };
                return {
                    id: index + 1,
                    fullname: item.fullname,
                    email: item.email,
                    contact: item.contact,
                    address: item.address,
                    infoBTN: (
                        <i 
                            className="btn btn-primary btn-sm" 
                            onClick={() => fetchDataForItem(item.id, userData)}
                        >
                            info
                        </i>
                    )
                };
            });

            setRows(transformedData);
        } catch (e) {
            console.error("Error fetching calendar events:", e);
        } finally {
            setLoading(false); // Optional: Reset loading state
        }
    }, [startDate, endDate, packageId, fetchDataForItem]);


    useEffect(() => {
        getCalenderEvents();
    }, [getCalenderEvents]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleToggleTable = () => {
        setIsTableExpanded((prev) => !prev); // Toggle table visibility
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value?.toLowerCase());
        setCurrentPage(1); // Reset to the first page on new search
    };

    // Filter rows based on search query
    const filteredRows = rows.filter((row) => {
        return (
            row.fullname?.toLowerCase().includes(searchQuery) ||
            row.email?.toLowerCase().includes(searchQuery) ||
            row.contact?.toLowerCase().includes(searchQuery)
        );
    });

    // Calculate pagination
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

    return (
            <div className="student-div mt-5">
                <Row className="g-3">
                    <Col xl="12">
                        <div className="d-flex justify-content-between align-items-center">
                            <Button variant="primary" onClick={handleToggleTable} className="d-flex align-items-center gap-2 mb-4">
                                {isTableExpanded ? <FaChevronUp /> : <FaChevronDown />}
                                {isTableExpanded ? "Minimize Table" : "Expand Table"}
                            </Button>
                        </div>
                    </Col>
                </Row>
                
                {isTableExpanded && (
                    <Row className="g-3 mb-4 align-items-center">
                        <h5 className="mb-3 fw-bold">Student Info</h5>
                        <Col xl="8">
                            <InputGroup className="mb-3" style={{ maxWidth: '400px' }}>
                                <FormControl
                                    placeholder="Search by title"
                                    aria-label="Search by title"
                                    aria-describedby="basic-addon2"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <InputGroup.Text id="basic-addon2">
                                    <FaSearch />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        {packageId && (
                            <>
                                <Col xl="2">
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            style={{ maxWidth: '150px' }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xl="2">
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            style={{ maxWidth: '150px' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>
                )}

                { loading ? ( // Show loader if data is still being fetched
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ): isTableExpanded ? (
                    <Row className="g-3">
                        <Col xl="12">
                            <Card className="card-one p-3">
                                <Card.Body className="overflow-hidden px-0 pb-3">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                {columns.map((col) => (
                                                    <th key={col.field} style={{ width: col.width }}>{col.headerName}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRows.length > 0 ? (
                                                currentRows.map((row) => (
                                                    <tr key={row.id}>
                                                        {columns.map((col) => (
                                                            <td key={col.field}>{row[col.field]}</td>
                                                        ))}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={columns.length} className="text-center">No Record Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Prev
                                            onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
                                        />

                                        {Array.from({ length: totalPages }, (_, index) => {
                                            const pageNumber = index + 1;

                                            // Determine the range of page numbers to show
                                            const startPage = Math.max(1, currentPage - 2);
                                            const endPage = Math.min(totalPages, currentPage + 2);

                                            // Show only if the page number is within the desired range
                                            if (pageNumber >= startPage && pageNumber <= endPage) {
                                                return (
                                                    <Pagination.Item
                                                        key={pageNumber}
                                                        active={pageNumber === currentPage}
                                                        onClick={() => handlePageChange(pageNumber)}
                                                    >
                                                        {pageNumber}
                                                    </Pagination.Item>
                                                );
                                            }

                                            return null;
                                        })}

                                        <Pagination.Next
                                            onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}
                                        />
                                    </Pagination>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Card className="p-3">
                        <h5 className="mb-3 fw-bold">Student Info</h5>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    {columns.map((col) => (
                                        <th key={col.field} style={{ width: col.width }}>{col.headerName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.slice(0, 4).map((row) => (
                                    <tr key={row.id}>
                                        {columns.map((col) => (
                                            <td key={col.field}>{row[col.field]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                )}
                <StudentInfoModal show={showModal} handleClose={() => setShowModal(false)} data={modalData}/>
            </div>
    );
}

export default StudentInfo